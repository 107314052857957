import React from 'react'
import { Link } from 'gatsby'

import { Container, Section, StyledLink } from '@components/common'
import { BlogSidebar } from '.'
import { Tag } from '@mrs-ui'

type ImageNode = {
  sourceUrl: string
}
type FeaturedImage = {
  node: ImageNode
}
type Post = {
  id: string
  title: string
  excerpt: string
  slug: string
  date: string
  featuredImage: FeaturedImage
  acf?: {
    clientLogo: { sourceUrl: string }
    clientName: string
    industry: string
  }
}

interface IBlogPostsGrid {
  data: any
  type?: 'blog' | 'case-studies'
}
const PostsGrid: React.FC<IBlogPostsGrid> = ({ data, type = 'blog' }) => {
  let typeSlug: string
  if (type === 'blog') typeSlug = '/blog'
  else if (type === 'case-studies') typeSlug = '/client-success'
  return (
    <Section verticalSpacing="default">
      <Container>
        <div className="flex flex-wrap">
          <div className="md:w-2/3 md:pr-24 w-full">
            {' '}
            {data.posts.nodes.map((post: Post) => (
              <div key={post.slug} className="mb-gap-large">
                {type === 'blog' ? (
                  <div className="text-mini text-shade400 mb-2">{post.date}</div>
                ) : (
                  <div className="mb-4">
                    <img
                      src={post.acf?.clientLogo.sourceUrl}
                      alt={`Logo for ${post.acf?.clientName}`}
                      className="h-12 mb-4 block"
                      loading="lazy"
                    />
                    <Tag content={post.acf!.industry} />
                  </div>
                )}

                <Link
                  to={`${typeSlug}/${post.slug}`}
                  className="text-contrast hover:underline focus-outline mb-3.5 block"
                >
                  <h3>{post.title}</h3>
                </Link>
                <p
                  className="text-small"
                  dangerouslySetInnerHTML={{
                    __html: post.excerpt,
                  }}
                />

                <p className="text-small">
                  <StyledLink to={`${typeSlug}/${post.slug}`}>
                    Read the {type === 'blog' ? 'article' : 'case study'}
                  </StyledLink>
                </p>
              </div>
            ))}
          </div>
          <aside className="md:w-1/3 w-full">
            <div className="sticky" style={{ top: '100px' }}>
              <BlogSidebar />
            </div>
          </aside>
        </div>
      </Container>
    </Section>
  )
}
export default PostsGrid
